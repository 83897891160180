import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';

let post_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;

function Profile()
{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useLocation();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [userData, setUserData] = useState({});
    const [userBasicInfoData, setUserBasicInfoData] = useState({});
    const [userPermanentAddressData, setUserPermanentAddressData] = useState({});
    const [userEducationCareerData, setUserEducationCareerData] = useState({});
    const [userSpiritualData, setUserSpiritualData] = useState({});
    const [userPhysicalData, setUserPhysicalData] = useState({});

    const [isInterested, setIsInterested] = useState();
    const [isShortlisted, setShortlisted] = useState();
    const [isFollowed, setFollowed] = useState();
    const [isIgnored, setIgnored] = useState();
    const [isReported, setReported] = useState();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    console.log(params);

    const fetchUserProfileData = async () => {
        try {
            post_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            post_params['member_id'] = params.state.member_id;
            post_params['user_id'] = user_id;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/member_profile',post_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
                .then(response => {
                    //console.log(response);
                    if(response.data.success)
                    {
                        console.log(response);
                        if(Object.keys(userData).length < 1)
                        {
                            setAllUsersData(response.data.member_data);
                        }
                        console.log(userData);
                    }
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response !== undefined && error.response !== '' && error.response !== null)
                        COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                    else
                        COMMONFUNCTIONS.commonMessageErrorToast(error.message);
                });
            
        } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

    function setAllUsersData (user_data)
    {
        setUserData(user_data);
        setUserBasicInfoData(user_data.basic_info);
        setUserPermanentAddressData(user_data.permanent_address);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
        setUserPhysicalData(user_data.physical_attributes);

        setIsInterested(user_data.is_interested);
        setShortlisted(user_data.is_shortlisted);
        setFollowed(user_data.is_followed);
        setIgnored(user_data.is_ignored);
        setReported(user_data.is_reported);
    }

    function getProfileImage(img_obj)
    {
        console.log(img_obj);
        if(img_obj !== undefined)
        {
            if(img_obj.profile_image.length > 1)
                image_name = img_obj.profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    useEffect(() => {
        COMMONFUNCTIONS.moveToTop();
        fetchUserProfileData();
    }, [userData]);

    return (
        <div>
            <Top/>
            <Header/>
            {/* PROFILE */}
                <section>
                    <div class="profi-pg profi-ban">
                        <div class="">
                            <div class="">
                                <div class="profile">
                                    <div class="pg-pro-big-im">
                                        <div class="s1">
                                            {/* <img src={"/assets/images/profiles/profile-large.jpg?x="+CONSTANTS.RANDOM_PWD} loading="lazy" class="pro" alt="" /> */}
                                            { getProfileImage(userData.profile_image) }
                                        </div>
                                        <div class="s3">
                                        {
                                            (isInterested) ? 
                                                <a className="cta express cta-chat w-25 active" title='Interest Expressed' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Expressed Interest In This Member.'); }} ><i class="fa fa-heart"></i></a>
                                                : 
                                                <a className="cta express cta-chat w-25" title='Express Interest' /* onClick={() => {gotoExpressInterest(props.item.member_id)}} */><i class="fa fa-heart"></i></a>
                                        }

                                        {
                                            (isShortlisted) ? 
                                                <a title='Shortlisted' /* onClick={() => { removeShortlist(user_id, props.item.member_id); }} */ className='cta express cta-chat w-25 active' ><i class="fa fa-list-ul"></i></a>
                                                : 
                                                <a title='Shortlist' /* onClick={() => { saveShortlist(user_id, props.item.member_id); }} */ class="cta express cta-chat w-25"><i class="fa fa-list-ul"></i></a>
                                        }

                                        {
                                            (isFollowed) ? 
                                                <a title='Unfollow' /* onClick={() => { addUnfollowList(user_id, props.item.member_id); }} */ className='cta express cta-chat w-25 active' ><i class="fa fa-star"></i></a>
                                                : 
                                                <a title='Follow' /* onClick={() => { saveFollowlist(user_id, props.item.member_id); }} */className='cta express cta-chat w-25' ><i class="fa fa-star"></i></a>
                                        }

                                        {
                                            (isIgnored) ? 
                                                <a title='Unfollow' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Ignored This Member.'); }} className='cta express cta-chat w-25 active' ><i class="fa fa-ban"></i></a>
                                                : 
                                                <a title='Ignore Profile' /* onClick={() => { gotoIgnore(props.item.member_id); }} */className='cta express cta-chat w-25 ' ><i class="fa fa-ban"></i></a>
                                        }

                                            {/* <a class="cta express cta-chat w-25" title='Express Interest'><i class="fa fa-heart"></i></a>
                                            <a class="cta express cta-chat w-25" title='Shortlist'><i class="fa fa-list-ul"></i></a>
                                            <a class="cta express cta-chat w-25" title='Follow'><i class="fa fa-star"></i></a>
                                            <a class="cta express cta-chat w-25" title='Ignore Profile'><i class="fa fa-ban"></i></a> */}
                                            {/* <a href="#!" class="cta fol cta-chat">Chat now</a>
                                            <span class="cta cta-sendint" data-toggle="modal" data-target="#sendInter">Send
                                                interest</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="profi-pg profi-bio">
                                    <div class="lhs">
                                        <div class="pro-pg-intro">
                                            <h1>{ COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) }</h1>
                                            <div class="pro-info-status">
                                                <span class="stat-1"><b>100</b> viewers</span>
                                                <span class="stat-2"><b>Available</b> online</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>City: <strong>{ userBasicInfoData.marital_status_title }</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-age.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Age: <strong>{ userData.age } Yrs</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Height: <strong>{ userData.height } Feet</strong></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <img src={"/assets/images/icon/pro-city.png?x="+CONSTANTS.RANDOM_PWD} loading="lazy" alt=""/>
                                                        <span>Job: <strong>{ userBasicInfoData.on_behalf_title }</strong></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* PROFILE ABOUT */}
                                        {
                                            (userData.introduction) ? <div class="pr-bio-c pr-bio-abo">
                                                <h3>About</h3>
                                                <p>{ userData.introduction }</p>
                                            </div> : ''
                                        }
                                        
                                        {/* END PROFILE ABOUT */}
                                        {/* PROFILE ABOUT */}
                                        {/* <div class="pr-bio-c pr-bio-gal" id="gallery">
                                            <h3>Photo gallery</h3>
                                            <div id="image-gallery">
                                                <div class="pro-gal-imag">
                                                    <div class="img-wrapper">
                                                        <a href="#!"><img src={"/assets/images/profiles/1.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                        <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pro-gal-imag">
                                                    <div class="img-wrapper">
                                                        <a href="#!"><img src={"/assets/images/profiles/6.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                        <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pro-gal-imag">
                                                    <div class="img-wrapper">
                                                        <a href="#!"><img src={"/assets/images/profiles/14.jpg" class="img-responsive?x="+CONSTANTS.RANDOM_PWD} alt="" /></a>
                                                        <div class="img-overlay"><i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* END PROFILE ABOUT */}

                                        {/* INTRODUCTION */}

                                        { (userData.introduction != "") ? <div class="pr-bio-c pr-bio-conta"><h3>Introduction</h3><b>{ userData.introduction }</b></div> : '' }
                                        
                                        {/* END INTRODUCTION */}

                                        {/* PROFILE ABOUT */}
                                        <div class="pr-bio-c pr-bio-conta">
                                            <h3>Contact info</h3>
                                            <ul>
                                                { (userData.mobile != "") ? <li><span><i class="fa fa-mobile" aria-hidden="true"></i><b>Phone:</b>{ userData.mobile }</span></li> : '' }

                                                { (userData.email != "") ? <li><span><i class="fa fa-envelope-o" aria-hidden="true"></i><b>Email:</b>{ userData.email }</span></li> : '' }

                                                {/* <li><span><i class="fa fa fa-map-marker" aria-hidden="true"></i><b>Address: </b>{ userPermanentAddressData.permanent_city_title+', '+userPermanentAddressData.permanent_state_title+', '+userPermanentAddressData.permanent_country_title }</span></li> */}
                                            </ul>
                                        </div>
                                        {/* END PROFILE ABOUT */}
                                        {/* PROFILE ABOUT */}
                                        <div class="pr-bio-c pr-bio-info">
                                            <h3>Personal information</h3>
                                            <ul>
                                                <li><b>Name:</b> { COMMONFUNCTIONS.camelCase(userData.first_name+' '+userData.last_name) }</li>

                                                { (userData.mobile != "") ? <li><b>Mobile:</b> { userData.mobile }</li> : '' }

                                                { (userData.email) ? <li><b>Email:</b> { userData.email }</li> : '' }
                                                
                                                <li><b>Age:</b> { userData.age } Years</li>
                                                <li><b>Date of birth:</b> { userData.date_of_birth }</li>

                                                { (userData.height > 0) ? <li><b>Height:</b> { userData.height } Feet</li> : '' }

                                                {/* { (userPhysicalData.weight) ? <li><b>Weight:</b> { userPhysicalData.weight } kg</li> : '' }
                                                
                                                { (userEducationCareerData.highest_education_title) ? <li><b>Education:</b> { userEducationCareerData.highest_education_title }</li> : '' }

                                                { (userSpiritualData.religion_title) ? <li><b>Religion:</b> { userSpiritualData.religion_title }</li> : '' }

                                                <li><b>Profession:</b> Working</li>
                                                <li><b>Company:</b> Google</li>
                                                <li><b>Position:</b> Web developer</li>
                                                <li><b>Salary:</b> $1000 p/m</li> */}
                                            </ul>
                                        </div>
                                        {/* END PROFILE ABOUT */}
                                        {/* PROFILE ABOUT
                                        <div class="pr-bio-c pr-bio-hob">
                                            <h3>Hobbies</h3>
                                            <ul>
                                                <li><span>Modelling</span></li>
                                                <li><span>Watching movies</span></li>
                                                <li><span>Playing volleyball</span></li>
                                                <li><span>Hangout with family</span></li>
                                                <li><span>Adventure travel</span></li>
                                                <li><span>Books reading</span></li>
                                                <li><span>Music</span></li>
                                                <li><span>Cooking</span></li>
                                                <li><span>Yoga</span></li>
                                            </ul>
                                        </div> */}
                                        {/* END PROFILE ABOUT */}
                                        {/* PROFILE ABOUT 
                                        <div class="pr-bio-c menu-pop-soci pr-bio-soc">
                                            <h3>Social media</h3>
                                            <ul>
                                                <li><a href="#!"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                                                <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            </ul>
                                        </div>*/}
                                        {/* END PROFILE ABOUT */}


                                    </div>

                                    {/* PROFILE lHS */}
                                    <div class="rhs">
                                        {/* HELP BOX */}
                                        <div class="prof-rhs-help">
                                            <div class="inn">
                                                <h3>Tell us your Needs</h3>
                                                <p>Tell us what kind of service or experts you are looking.</p>
                                                <a href="sign-up.html">Register for free</a>
                                            </div>
                                        </div>
                                        {/* END HELP BOX */}
                                        {/* RELATED PROFILES */}
                                        <div class="slid-inn pr-bio-c wedd-rel-pro">
                                            <h3>Related profiles</h3>
                                            <ul class="slider3">
                                                <li>
                                                    <div class="wedd-rel-box">
                                                        <div class="wedd-rel-img">
                                                            <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <span class="badge badge-success">21 Years old</span>
                                                        </div>
                                                        <div class="wedd-rel-con">
                                                            <h5>Christine</h5>
                                                            <span>City: <b>New York City</b></span>
                                                        </div>
                                                        <a href="profile-details.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="wedd-rel-box">
                                                        <div class="wedd-rel-img">
                                                            <img src={"/assets/images/profiles/2.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <span class="badge badge-success">21 Years old</span>
                                                        </div>
                                                        <div class="wedd-rel-con">
                                                            <h5>Christine</h5>
                                                            <span>City: <b>New York City</b></span>
                                                        </div>
                                                        <a href="profile-details.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="wedd-rel-box">
                                                        <div class="wedd-rel-img">
                                                            <img src={"/assets/images/profiles/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <span class="badge badge-success">21 Years old</span>
                                                        </div>
                                                        <div class="wedd-rel-con">
                                                            <h5>Christine</h5>
                                                            <span>City: <b>New York City</b></span>
                                                        </div>
                                                        <a href="profile-details.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="wedd-rel-box">
                                                        <div class="wedd-rel-img">
                                                            <img src={"/assets/images/profiles/4.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <span class="badge badge-success">21 Years old</span>
                                                        </div>
                                                        <div class="wedd-rel-con">
                                                            <h5>Christine</h5>
                                                            <span>City: <b>New York City</b></span>
                                                        </div>
                                                        <a href="profile-details.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="wedd-rel-box">
                                                        <div class="wedd-rel-img">
                                                            <img src={"/assets/images/profiles/6.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" />
                                                            <span class="badge badge-success">21 Years old</span>
                                                        </div>
                                                        <div class="wedd-rel-con">
                                                            <h5>Christine</h5>
                                                            <span>City: <b>New York City</b></span>
                                                        </div>
                                                        <a href="profile-details.html" class="fclick"></a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* END RELATED PROFILES */}
                                    </div>
                                    {/* END PROFILE lHS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END PROFILE */}
                {/* INTEREST POPUP */}
                <div class="modal fade" id="sendInter">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">Send interest to <span class="intename">Jolia</span></h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="lhs">
                                    <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" class="intephoto1" />
                                </div>
                                <div class="rhs">
                                    <h4><span class="intename1">Jolia</span> Can able to view the below details</h4>
                                    <ul>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_about" checked="" />
                                                <label for="pro_about">About section</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_photo" />
                                                <label for="pro_photo">Photo gallery</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_contact" />
                                                <label for="pro_contact">Contact info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_person" />
                                                <label for="pro_person">Personal info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_hobbi" />
                                                <label for="pro_hobbi">Hobbies</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_social" />
                                                <label for="pro_social">Social media</label>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="form-floating">
                                        <textarea class="form-control" id="comment" name="text"
                                            placeholder="Comment goes here"></textarea>
                                        <label for="comment">Write some message to <span class="intename"></span></label>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Send interest</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/* END INTEREST POPUP */}
            {/* END */}
            <Footer/>
        </div>
    )
}

export default Profile