import React, { useState, useEffect, useRef } from 'react'

import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom';

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import LeftSearch from '../../components/common/left_search';
import Footer from '../../components/common/footer';

import MemberItems from './member_items';

let home_search_params = {};
let temp_search_params = {};
let final_search_params = {};
let image_name = CONSTANTS.DEFAULT_PROFILE_IMAGE;
let page = 1;
let rcount = null;

let values = {};
 
const refresh = (setItems) => {};

export default function Members(props)
{
    const {t} = useTranslation();
    const [items, setItems] = React.useState([]);
    const navigate = useNavigate();

    const passed_params = useLocation();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    const [isInterested, setInterested] = useState();
    const [isShortlisted, setShortlisted] = useState();

    const [selectedMemberType, setSelectedMemberType] = useState(props.member_type);

    const [member_type, setMemberType] = useState(props.member_type);

    console.log(passed_params);
    if(passed_params.state !== null)
    {
        home_search_params = passed_params.state.search_params;
        temp_search_params = passed_params.state.search_params;
    }
        

    const [hasMore, setHasMore] = useState(true);
    //const [search_params, setSearchParams] = useState({});
    const [mobFilterShow, setMobFilterShow] = useState('block');
    const [listingViewClass, setListingViewClass] = useState('all-list-sh');
    const [gridIconClassActive, setGridIconClass] = useState('');
    const [listIconClassActive, setListIconClass] = useState('act');

    const [textLoginModal, setTextLoginModal] = useState();
    const [contentExpressModal, setContentExpressModal] = useState();
    const [expressModalHeaderText, setExpressModalHeaderText] = useState(t('confirm'));

    const modalOpenLoginBtnRef = useRef(null);
    const modalCloseLoginBtnRef = useRef(null);
    const modalOpenExpressBtnRef = useRef(null);
    const modalCloseExpressBtnRef = useRef(null);

    const changeFilterGender = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['gender'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterAge = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['age_range'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMemberId = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['member_id'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMaritalStatus = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['marital_status'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterReligion = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['religion'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterLanguage = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['language'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterProfession = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['profession'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterCountry = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['country'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterState = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['state'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterCity = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        temp_search_params['city'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[])
    }

    const changeFilterMemberType = (event) => {
        //recordsListPanel = true;
        //console.log(event.target.value);
        setMemberType(null);
        temp_search_params['search_member_type'] = event.target.value;
        temp_search_params['count'] = 0;
        final_search_params['count'] = 0;
        rcount = 0;
        setHasMore(true);
        fetchData(setItems,[]);
        setSelectedMemberType(event.target.value);
    }

    const fetchData = (setItems, items) => {

        final_search_params = temp_search_params;
        final_search_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        final_search_params['count'] = rcount;
        final_search_params['method'] = 'search';
        //final_search_params['search_member_type'] = 'all';
        if(member_type !== null)
        {
            console.log(member_type);
            final_search_params['search_member_type'] = member_type;
        }

        if(isLoggedIn)
        {
            final_search_params['user_id'] = user_id;
        }
        else if(page > 1)
        {
            setTextLoginModal('Please Log In To View More Profiles.');
            modalOpenLoginBtnRef.current.click();
            setHasMore(false);
            return false;
        }

        //console.log(final_search_params);
        /* if(Object.keys(params_to_search).length > 0)
        {
            final_search_params = params_to_search;
        } */

        /* alert(Object.keys(params_to_search).length);
        alert(Object.keys(temp_search_params).length);
        alert(Object.keys(search_params).length); */

        if(typeof final_search_params.age_range !== 'undefined' && final_search_params.age_range !== '' && final_search_params.age_range !== null)
        {
            //console.log('age_range::'+final_search_params.age_range);

            let age_array = final_search_params.age_range.split('-');

            //console.log(age_array);

            final_search_params['aged_from'] = age_array[0];
            final_search_params['aged_to'] = age_array[1];
        }

          //axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=10`)
          axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/ajax_member_list',final_search_params, {headers: COMMONFUNCTIONS.getPostHeaders()})
         .then((res) => {
          console.log(res);
          if(res.data.count < 10)
          {
              //setIsLoading(false);
              setHasMore(false);
              if(res.data.count < 1)
                COMMONFUNCTIONS.commonMessageErrorToast('No more data to load.');
          }
          setItems([...items, ...res.data.records]);
          page = page + 1;
          rcount = rcount + 10;
          console.log(rcount);
         });
    };

    function updateExpressInterest(member_id)
    {
        if (isLoggedIn)
        {
            let act_status = saveExpressInterest(user_id ,member_id);

            //modalCloseExpressBtnRef.current.click();
        }
		else
		{
			setTextLoginModal('Please Log In To Express Interest On This Member.');
            modalOpenLoginBtnRef.current.click();
		}
    }

    function saveExpressInterest(user_id, member_id)
    {
        values['user_id'] = user_id;
        values['member_id'] = member_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        let resp_status = false;
    
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_interest',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response);
            if(response.data.success)
            {
                COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                resp_status = true;
                //setStates(response.data.state_data);
                setInterested(true);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                resp_status = false;
                setInterested(false);
            }
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            /* if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message); */
        });
    }

    function gotoUserProfile(member_id)
    {
        //console.log(member_id);
        navigate("/profile-details.html", { state: {member_id : member_id}, replace: true });
    }

    function changeRecordLayout(type)
    {
        if(type == 'grid') {
            setGridIconClass('act');
            setListIconClass('');
            setListingViewClass('all-list-sh view-grid');
        }
        else{
            setGridIconClass('');
            setListIconClass('act');
            setListingViewClass('all-list-sh');
        }
    }

    function getProfileImage(img_obj)
    {
        if(img_obj !== undefined)
        {
            //console.log(img_obj);
            let image_json_data = JSON.parse(img_obj);

            if(image_json_data[0].profile_image.length > 1)
                image_name = image_json_data[0].profile_image;
        }

        let profile_image = CONSTANTS.SERVER_BASE_URL.concat(CONSTANTS.PROFILE_DIR_PATH, image_name);

        return <img src={profile_image} alt="" />;
    }

    function gotoLogin()
    {
        COMMONFUNCTIONS.moveToTop();
        navigate("/login.html");
    }
    
    React.useEffect(()=>{
        fetchData(setItems,items)
    },[])

    return (
        <>
            <Top/>
            <Header/>
            {/* SUB-HEADING */}
                <section>
                    <div class="all-pro-head">
                        <div class="container">
                            <div class="row">
                                <h1>Lakhs of Happy Marriages</h1>
                                <a href="sign-up.html">Join now for Free <i class="fa fa-handshake-o" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                    {/* FILTER ON MOBILE VIEW */}
                    <div class="fil-mob fil-mob-act" onClick={() => { setMobFilterShow('block'); }}>
                        <h4>Profile filters <i class="fa fa-filter" aria-hidden="true"></i> </h4>
                    </div>
                </section>
                {/* END */}

                {/* START */}
                <section>
                    <div class="all-weddpro all-jobs all-serexp">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-3 form-select fil-mob-view" style={{display:mobFilterShow}}>
                                    <LeftSearch search_params={home_search_params} changeFilterGenderFunction={changeFilterGender} changeFilterAgeFunction={changeFilterAge} changeFilterMemberIdFunction={changeFilterMemberId} changeFilterMaritalStatusFunction={changeFilterMaritalStatus} changeFilterReligionFunction={changeFilterReligion} changeFilterProfessionFunction={changeFilterProfession} changeFilterCountryFunction={changeFilterCountry} changeFilterStateFunction={changeFilterState} changeFilterCityFunction={changeFilterCity} changeFilterMemberTypeFunction={changeFilterMemberType} changeFilterLanguageFunction={changeFilterLanguage} fetchData={fetchData} setMobFilterShow={setMobFilterShow} member_type={member_type} selectedMemberType={selectedMemberType} />
                                </div>
                                <div class="col-md-9">
                                    <div class="short-all">
                                        <div class="short-lhs">
                                            {/* Showing <b>32</b> profiles */}
                                        </div>
                                        <div class="short-rhs">
                                            <ul>
                                                <li>
                                                    Sort by:
                                                </li>
                                                <li>
                                                    <div class="form-group">
                                                        <select class="chosen-select">
                                                            <option value="">Most relative</option>
                                                            <option value="Men">Date listed: Newest</option>
                                                            <option value="Men">Date listed: Oldest</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class= {"sort-grid sort-grid-1 "+gridIconClassActive} onClick={() => { changeRecordLayout('grid'); }}>
                                                        <i class="fa fa-th-large" aria-hidden="true"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class={"sort-grid sort-grid-2 "+listIconClassActive} onClick={() => { changeRecordLayout('list'); }}>
                                                        <i class="fa fa-bars" aria-hidden="true"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class={listingViewClass}>
                                        <ul>
                                            <InfiniteScroll
                                                dataLength={items.length} //This is important field to render the next data
                                                next={() => {
                                                    fetchData(setItems, items);
                                                }}
                                                hasMore={hasMore}
                                                loader={<h4><center>Loading...</center></h4>}
                                                /* endMessage={
                                                <p style={{ textAlign: "center" }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                                } */
                                                // below props only if you need pull down functionality
                                                refreshFunction={refresh}
                                                endMessage={<p><center>No more data to load.</center></p>}
                                                /* pullDownToRefresh
                                                pullDownToRefreshThreshold={50}
                                                pullDownToRefreshContent={
                                                    <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
                                                }
                                                releaseToRefreshContent={
                                                    <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
                                                } */
                                            >
                                                <div style={{ minHeight: "0" }}>
                                                { items.map(item => (
                                                        <MemberItems item={item} setTextLoginModal={setTextLoginModal} modalOpenLoginBtnRef={modalOpenLoginBtnRef} modalOpenExpressBtnRef={modalOpenExpressBtnRef} setContentExpressModal={setContentExpressModal} setExpressModalHeaderText={setExpressModalHeaderText} setSelectedMemberId={setSelectedMemberId} updateExpressInterest={updateExpressInterest} getProfileImage={getProfileImage} gotoUserProfile={gotoUserProfile} />
                                                    ))}
                                                </div>
                                            </InfiniteScroll>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END */}


                {/* INTEREST POPUP */}
                <div class="modal fade" id="sendInter">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">Send interest to <span class="intename2">Jolia</span></h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="lhs">
                                    <img src={"/assets/images/profiles/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" class="intephoto2" />
                                </div>
                                <div class="rhs">
                                    <h4>Permissions: <span class="intename2">Jolia</span> Can able to view the below details</h4>
                                    <ul>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_about" checked="" />
                                                <label for="pro_about">About section</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_photo" />
                                                <label for="pro_photo">Photo gallery</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_contact" />
                                                <label for="pro_contact">Contact info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_person" />
                                                <label for="pro_person">Personal info</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_hobbi" />
                                                <label for="pro_hobbi">Hobbies</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="chbox">
                                                <input type="checkbox" id="pro_social" />
                                                <label for="pro_social">Social media</label>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="form-floating">
                                        <textarea class="form-control" id="comment" name="text"
                                            placeholder="Comment goes here"></textarea>
                                        <label for="comment">Write some message to <span class="intename"></span></label>
                                    </div>
                                </div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Send interest</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/* END INTEREST POPUP */}

                {/* CHAT CONVERSATION BOX START */}
                <div class="chatbox">
                    <span class="comm-msg-pop-clo"><i class="fa fa-times" aria-hidden="true"></i></span>

                    <div class="inn">
                        <form name="new_chat_form" method="post">
                            <div class="s1">
                                <img src={"/assets/images/user/2.jpg?x="+CONSTANTS.RANDOM_PWD} class="intephoto2" alt="" />
                                <h4><b class="intename2">Julia</b>,</h4>
                                <span class="avlsta avilyes">Available online</span>
                            </div>
                            <div class="s2 chat-box-messages">
                                <span class="chat-wel">Start a new chat!!! now</span>
                                <div class="chat-con">
                                    <div class="chat-lhs">Hi</div>
                                    <div class="chat-rhs">Hi</div>
                                </div>
                                {/*<span>Start A New Chat!!! Now</span>*/}
                            </div>
                            <div class="s3">
                                <input type="text" name="chat_message" placeholder="Type a message here.." required="" />
                                <button id="chat_send1" name="chat_send" type="submit">Send <i class="fa fa-paper-plane-o"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <a ref={modalOpenLoginBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#login_notice_modal">login</a>
                <div class="modal fade" id="login_notice_modal">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content notification-modal">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{t('please_log_in')}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="text-center">{textLoginModal}</div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => { modalCloseLoginBtnRef.current.click();gotoLogin(); }}>Login</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalCloseLoginBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <a ref={modalOpenExpressBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#expression_modal">express</a>
                <div class="modal fade" id="expression_modal">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content notification-modal">

                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{expressModalHeaderText}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            <div class="modal-body seninter">
                                <div class="text-center">{contentExpressModal}</div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => { updateExpressInterest(selectedMemberId);modalCloseExpressBtnRef.current.click(); }}>Confirm</button>
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalCloseExpressBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* END */}
                <Footer/>
            </>
    );
}