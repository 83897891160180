import React, { useEffect, useState } from 'react'

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Link } from "react-router-dom";
import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';
import {useTranslation} from 'react-i18next'

import LoginMenus from '../common/login_menu'

let values=  {};

function Header()
{
    const {t} = useTranslation();

    const [userDetails, getUserDetails] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserDetails')));
    const [userPackageDetails, setUserPackageDetails] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserPackageDetails')));
    const [userRemPackageDetails, setUserRemPackageDetails] = useState(JSON.parse(COMMONFUNCTIONS.getSession('UserRemPackageDetails')));
    const [isLoggedIn, checkLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [headProClass, assignHeadProActClass] = useState('');
    const [mobileMenuClass, assignMobileMenuActClass] = useState('');
    
    const [userPermanentAddressData, setUserPermanentAddressData] = useState({});
    const [userEducationCareerData, setUserEducationCareerData] = useState({});
    const [userSpiritualData, setUserSpiritualData] = useState({});
    const [userPhysicalData, setUserPhysicalData] = useState({});

    let full_name = 'Visitor';
    let occupation = 'I am visitor';
    let user_avatar = '/assets/images/profiles/man.png';
    if(isLoggedIn)
    {
        user_avatar = CONSTANTS.SERVER_BASE_URL+CONSTANTS.PROFILE_DIR_PATH+userDetails.profile_image.thumb+'&r='+Math. random();
        full_name = userDetails.first_name+' '+userDetails.last_name;
        occupation = userDetails.education_and_career.occupation_title;
    }

    useEffect(() => {
        if(isLoggedIn)
            setAllUsersData(userDetails);
        if(isLoggedIn && Object.is(userPackageDetails, null))
            getUserPackageDetails();

            checkLoggedIn(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    })

    function getUserPackageDetails()
    {
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        values['member_id'] = userDetails.member_id;
        values['user_id'] = userDetails.member_id;
        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/getUsersPackage', values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            console.log(response.data.success);
            if(response.data.success === true)
            {
                console.log('set data');
                console.log(response.data);
                setUserPackageDetails(response.data.package_info);
                setUserRemPackageDetails(response.data.remaining_package_info);
                COMMONFUNCTIONS.setSession('UserPackageDetails', response.data.package_info);
                COMMONFUNCTIONS.setSession('UserRemPackageDetails', response.data.remaining_package_info);
            }
            else
            {
                COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
            }
        })
        .catch(error => {
            console.log(error);
            //COMMONFUNCTIONS.commonMessageErrorToast('Failed to fetch data.');
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    function setAllUsersData (user_data)
    {
        setUserPermanentAddressData(user_data.permanent_address);
        setUserEducationCareerData(user_data.education_and_career);
        setUserSpiritualData(user_data.spiritual_and_social_background);
        setUserPhysicalData(user_data.physical_attributes);
    }

    //console.log(userDetails.member_id);
    
    return (
        <div>
          {/* MENU POPUP  */}
          <div class="menu-pop menu-pop1">
              <span class="menu-pop-clo"><i class="fa fa-times" aria-hidden="true"></i></span>
              <div class="inn">
                  <img src={"/assets/images/logo-b.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" class="logo-brand-only" />
                  <p><strong>Best Wedding Matrimony</strong> lacinia viverra lectus. Fusce imperdiet ullamcorper metus eu
                      fringilla.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <ul class="menu-pop-info">
                      <li><Link to="/#!"><i class="fa fa-phone" aria-hidden="true"></i>+92 (8800) 68 - 8960</Link></li>
                      <li><Link to="/#!"><i class="fa fa-whatsapp" aria-hidden="true"></i>+92 (8800) 68 - 8960</Link></li>
                      <li><Link to="/#!"><i class="fa fa-envelope-o" aria-hidden="true"></i>help@company.com</Link></li>
                      <li><Link to="/#!"><i class="fa fa-map-marker" aria-hidden="true"></i>3812 Lena Lane City Jackson Mississippi</Link></li>
                  </ul>
                  <div class="menu-pop-help">
                      <h4>Support Team</h4>
                      <div class="user-pro"> <img src={user_avatar} alt={full_name} loading="lazy" /> </div>
                      <div class="user-bio">
                          <h5>{full_name}</h5>
                          <span className='col-md-12'>{occupation}</span>
                          <Link to="/enquiry.html" class="btn btn-primary btn-sm">Ask your doubts</Link> 
                      </div>
                  </div>
                  <div class="menu-pop-soci">
                      <ul>
                          <li><Link to="/#!"><i class="fa fa-facebook" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-twitter" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-instagram" aria-hidden="true"></i></Link></li>
                      </ul>
                  </div>
              </div>
          </div>
          {/* END  */}
          {/* CONTACT EXPERT  */}
          <div class={"menu-pop menu-pop2 "+headProClass}>
              <span class="menu-pop-clo" onClick={() => { assignHeadProActClass(""); }}><i class="fa fa-times" aria-hidden="true"></i></span>
              <div class="inn">
                  <div class="menu-pop-help">
                      <h4>Welcome !!</h4>
                      <div class="user-pro"> <Link to={(isLoggedIn) ? "/my-profile.html" : "/login.html" }><img src={user_avatar} alt={full_name} loading="lazy" /></Link> </div>
                      <div class="user-bio">
                            <h5><Link className='bg-transparent black-text' to={(isLoggedIn) ? "/my-profile.html" : "/login.html" }>{full_name}</Link></h5>
                          <span className='col-md-12'>{(isLoggedIn) ? "("+ userDetails.member_profile_id +")" : ""}</span>
                          <span className='col-md-12'>{occupation}</span>
                          {(isLoggedIn) ? <div class="pr-bio-conta"><ul className='text-left'>
                                    <li className='p-b-10'><span><i class="fa fa-mobile" aria-hidden="true"></i>{userDetails.mobile}</span></li>
                                    <li className='p-b-10'><span><i class="fa fa-envelope-o" aria-hidden="true"></i>{userDetails.email}</span>
                                    </li>
                                    <li className='p-b-10'><span><i class="fa fa fa-map-marker" aria-hidden="true"></i>{userDetails.present_address.city_title}, {userDetails.present_address.state_title}, {userDetails.present_address.country_title}</span></li>
                                </ul></div> : <Link to="/login.html" class="btn btn-primary btn-sm">Login</Link> }
                      </div>
                  </div>

                    {/* PROFILE ABOUT */}
                    {(isLoggedIn) ? 
                        <div class="pr-bio-c pr-bio-info text-left m-t-20">
                            <h5 className='m-b-10'>Personal Information</h5>
                            <ul>
                                <li><b>{ userDetails.age } Years</b></li>
                                <li><b>{ userDetails.date_of_birth } Born</b></li>

                                { (userDetails.height > 0) ? <li><b>{ userDetails.height } Feet</b></li> : '' }

                                { (userPhysicalData.weight) ? <li><b>{ userPhysicalData.weight } KG</b></li> : '' }
                                
                                { (userEducationCareerData.highest_education_title) ? <li><b>{ userEducationCareerData.highest_education_title }</b></li> : '' }

                                { (userEducationCareerData.highest_education_title) ? <li><b>{ userEducationCareerData.occupation_title }</b></li> : '' }

                                { (userSpiritualData.caste_title) ? <li><b>{ userSpiritualData.caste_title }</b></li> : '' }

                                { (userSpiritualData.religion_title) ? <li><b>{ userSpiritualData.religion_title }</b></li> : '' }
                            </ul>
                        </div> : ''
                    }
                    {/* END PROFILE ABOUT */}

                    {/* PROFILE ABOUT */}
                    {(isLoggedIn && userPackageDetails != null ) ? 
                        <div class="pr-bio-c pr-bio-info text-left m-t-20">
                            <h5 className='m-b-10'>Package</h5>
                            <ul>
                                <li><b>{ userPackageDetails.current_package }</b></li>
                                <li><b>&#8377; { userPackageDetails.package_price }</b></li>
                                <li><b>{ userPackageDetails.payment_type }</b></li>
                            </ul>
                        </div> : ''
                    }

                    {(isLoggedIn) &&
                        <div class="text-left m-b-20">
                            {/* <span className='btn btn-primary m-1'>Gallery</span>
                            <span className='btn btn-primary m-1'>Happy Story</span> */}
                            <Link to="/my-package.html" className='btn btn-primary m-1'>My Package</Link>
                            <Link to="/payment-info.html" className='btn btn-primary m-1'>Payment Info</Link>
                            <Link to="/picture-privacy.html" className='btn btn-primary m-1'>Picture Privacy</Link>
                            <Link to="/change-password.html" className='btn btn-primary m-1'>Change Password</Link>
                            <Link to="/delete-account.html" className='btn btn-danger m-1'>Delete Account</Link>
                        </div>
                    }
                    {/* END PROFILE ABOUT */}
                  {/* <div class="menu-pop-soci">
                      <ul>
                          <li><Link to="/#!"><i class="fa fa-facebook" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-twitter" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-instagram" aria-hidden="true"></i></Link></li>
                      </ul>
                  </div>
                  <div class="late-news">
                      <h4>Latest news</h4>
                      <ul>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/4.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                      </ul>
                  </div> */}
                  {/* HELP BOX  */}
                  <div class="prof-rhs-help">
                      <div class="inn">
                          <h3>Tell us your Needs</h3>
                          <p>Tell us what kind of service you are looking for.</p>
                          {(isLoggedIn) ? <Link to="/contact.html">Contact Us</Link> : <Link to="/sign-up.html" className='font-25 p-y-10 p-x-25'>Register</Link> }
                      </div>
                  </div>
                  {/* END HELP BOX  */} 

                  {(isLoggedIn) ? <Link to="/logout.html" class="btn btn-danger btn-sm">Logout</Link> : '' }
              </div>
          </div>
          {/* END  */}
          {/* MAIN MENU  */}
          <div class="hom-top">
              <div class="container">
                  <div class="row">
                      <div class="hom-nav">
                          {/* LOGO  */}
                          <div class="logo"> {/* <span class="menu desk-menu"> <i></i><i></i><i></i> </span> */} <Link to="/index.html" class="logo-brand"><img src={"/assets/images/header_logo.png?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy"
                              class="ic-logo" /></Link> </div>
                          {/* EXPLORE MENU  */}
                          <div class="bl">
                              <ul>
                                <li><Link to="/index.html">{t('home')}</Link></li>
                                  {/* <li class="smenu-pare">
                                      <span class="smenu">Explore</span>
                                      <div class="smenu-open smenu-box">
                                          <div class="container">
                                              <div class="row">
                                                  <h4 class="tit">Explore category</h4>
                                                  <ul>
                                                      <li>
                                                          <div class="menu-box menu-box-2">
                                                              <h5>Browse profiles <span>1200+ Verified profiles</span></h5>
                                                              <span class="explor-cta">More details</span> <Link to="/all-profiles.html" class="fclick"></Link> 
                                                          </div>
                                                      </li>
                                                      <li>
                                                          <div class="menu-box menu-box-1">
                                                              <h5>Wedding page <span>Make reservation</span></h5>
                                                              <span class="explor-cta">More details</span> <Link to="/wedding.html" class="fclick"></Link> 
                                                          </div>
                                                      </li>
                                                      <li>
                                                          <div class="menu-box menu-box-3">
                                                              <h5>All Services<span>Lorem ipsum dummy</span></h5>
                                                              <span class="explor-cta">More details</span> <Link to="/services.html" class="fclick"></Link> 
                                                          </div>
                                                      </li>
                                                      <li>
                                                          <div class="menu-box menu-box-4">
                                                              <h5>Join Now <span>Lorem ipsum dummy</span></h5>
                                                              <span class="explor-cta">More details</span> <Link to="/plans.html" class="fclick"></Link> 
                                                          </div>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li class="smenu-pare">
                                      <span class="smenu">All pages</span>
                                      <div class="smenu-open smenu-multi">
                                          <div class="container">
                                              <div class="row">
                                                  <div class="multi-col">
                                                      <div class="inn">
                                                          <h4>Page sets 1</h4>
                                                          <ul>
                                                              <li><Link to="/all-profiles.html">All profiles</Link></li>
                                                              <li><Link to="/profile-details.html">Profile details</Link></li>
                                                              <li><Link to="/wedding.html">Wedding</Link></li>
                                                              <li><Link to="/wedding-video.html">Wedding video</Link></li>
                                                              <li><Link to="/services.html">Our Services</Link></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div class="multi-col">
                                                      <div class="inn">
                                                          <h4>Page sets 2</h4>
                                                          <ul>
                                                              <li><Link to="/plans.html">Pricing plans</Link></li>
                                                              {/* <li><Link to="/login.html">Login</Link></li>
                                                              <li><Link to="/login.html">Login</Link></li>
                                                              <li><Link to="/sign-up.html">Sign-up</Link></li>
                                                              <li><Link to="/photo-gallery.html">Photo gallery</Link></li>
                                                              <li><Link to="/photo-gallery-1.html">Photo gallery 1</Link></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div class="multi-col">
                                                      <div class="inn">
                                                          <h4>Page sets 3</h4>
                                                          <ul>
                                                              <li><Link to="/contact.html">Contact</Link></li>
                                                              <li><Link to="/about.html">About</Link></li>
                                                              <li><Link to="/blog.html">Blog</Link></li>
                                                              <li><Link to="/blog-detail.html">Blog detail</Link></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <div class="multi-col">
                                                      <div class="inn">
                                                          <h4>Page sets 4</h4>
                                                          <ul>
                                                              <li><Link to="/enquiry.html">Ask your doubts</Link></li>
                                                              <li><Link to="/make-reservation.html">Make Reservation</Link></li>
                                                              <li><Link to="/faq.html">FAQ</Link></li>
                                                              <li><Link to="/coming-soon.html" target="_blank">Coming soon</Link> </li>
                                                              <li><Link to="/404.html">404</Link></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                                  <li class="smenu-pare">
                                      <span class="smenu">Top pages</span>
                                      <div class="smenu-open smenu-single">
                                          <ul>
                                              <li><Link to="/all-profiles.html">All profiles</Link></li>
                                              <li><Link to="/profile-details.html">Profile details</Link></li>
                                              <li><Link to="/wedding.html">Wedding</Link></li>
                                              <li><Link to="/blog.html">Blog</Link></li>
                                              <li><Link to="/blog-detail.html">Blog detail</Link></li>
                                              <li><Link to="/about.html">About</Link></li>
                                              <li><Link to="/contact.html">Contact</Link></li>
                                              <li><Link to="/photo-gallery.html">Photo gallery</Link></li>
                                              <li><Link to="/photo-gallery-1.html">Photo gallery 1</Link></li>
                                              <li><Link to="/login.html">Login</Link></li>
                                              <li><Link to="/sign-up.html">Sign-up</Link></li>
                                              <li><Link to="/plans.html">Pricing plans</Link></li>
                                          </ul>
                                      </div>
                                  </li> */}
                                  <li><Link to="/about.html">About</Link></li>
                                  {/* <li><Link to="/members.html">Active Members</Link></li> */}
                                  <li class="smenu-pare">
                                        <span class="smenu">Active Members</span>
                                        <div class="smenu-open smenu-single">
                                            <ul>
                                                {/* <li><Link to="/members.html">All Members</Link></li>
                                                <li><Link to="/members.html">Premium Members</Link></li>
                                                <li><Link to="/members.html">Free Members</Link></li> */}
                                                <li><a href="/members.html">All Members</a></li>
                                                <li><a href="/premium.html">Premium Members</a></li>
                                                <li><a href="/free.html">Free Members</a></li>
                                            </ul>
                                        </div>
                                  </li>
                                  {/* <li><Link to="/photo-gallery.html">Gallery</Link></li> */}
                                  {/* <li class="smenu-pare">
                                        <span class="smenu">Gallery</span>
                                        <div class="smenu-open smenu-single">
                                            <ul>
                                                <li><Link to="/photo-gallery.html">Picture</Link></li>
                                                <li><Link to="/photo-gallery.html">Videos</Link></li>
                                            </ul>
                                        </div>
                                  </li> */}
                                  <li><Link to="/plans.html">Plans</Link></li>
                                  {/* <li><Link to="/login.html">Sign in</Link></li> */}
                                  {(isLoggedIn) ? <li className='ml-5'><Link to="/logout.html">Logout</Link></li> : <LoginMenus/> }
                                  {/* <LoginMenus/> */}
                              </ul>
                          </div>
                          {/* USER PROFILE  */}
                          <div class="al">
                              <div class="head-pro">
                                  <img src={user_avatar} alt={full_name} loading="lazy" /> {/* <b>Advisor</b><br /> */}
                                  <h4 className="p-t-25">{ COMMONFUNCTIONS.camelCase(full_name)}</h4>
                                  <span class='fclick' title={COMMONFUNCTIONS.camelCase(full_name)} onClick={() => { assignHeadProActClass('act'); } }></span> 
                              </div>
                          </div>
                          {/*MOBILE MENU */}
                          <div class="mob-menu">
                              <div class="mob-me-ic"> <span class="ser-open mobile-ser"> <img src={"/assets/images/icon/search.svg?x="+CONSTANTS.RANDOM_PWD} alt="" /> </span> <span class="mobile-exprt" data-mob="dashbord"> <img src={"/assets/images/icon/users.svg?x="+CONSTANTS.RANDOM_PWD} alt="" /> </span> <span class="mobile-menu" data-mob="mobile" onClick={() => { assignMobileMenuActClass('act'); }}> <img src={"/assets/images/icon/menu.svg?x="+CONSTANTS.RANDOM_PWD} alt="" /> </span> </div>
                          </div>
                          {/*END MOBILE MENU */} 
                      </div>
                  </div>
              </div>
          </div>
          {/* END  */}
          {/* EXPLORE MENU POPUP  */}
          <div class={"mob-me-all mobile_menu " + mobileMenuClass}>
              <div class="mob-me-clo" onClick={() => { assignMobileMenuActClass(''); }}><img src={"/assets/images/icon/close.svg?x="+CONSTANTS.RANDOM_PWD} alt="" /></div>
              <div class="mv-bus">
                    <ul>
                        <li><Link to="/index.html">{t('home')}</Link></li>
                        <li><Link to="/about.html">About</Link></li>
                        <li><Link to="/members.html">All Members</Link></li>
                        <li><Link to="/members.html">Premium Members</Link></li>
                        <li><Link to="/members.html">Free Members</Link></li>
                        <li><Link to="/plans.html">Plans</Link></li>
                        {(isLoggedIn) ? <li><Link to="/logout.html">Logout</Link></li> : <LoginMenus/> }
                    </ul>
                  <div class="menu-pop-help">
                      <h4>My Self</h4>
                      <Link to={(isLoggedIn) ? "/my-profile.html" : "/login.html" }>
                        <div class="user-pro"> <img src={user_avatar} alt={full_name} loading="lazy" /> </div>
                        <div class="user-bio">
                            <h5>{full_name}</h5>
                            <span className='col-md-12'>{occupation}</span>
                        </div>
                      </Link>
                  </div>
                  {/* PROFILE ABOUT */}
                  {(isLoggedIn) ? 
                        <div class="pr-bio-c pr-bio-info text-left m-t-20">
                            <h5 className='m-b-10'>Personal Information</h5>
                            <ul>
                                <li><b>{ userDetails.age } Years</b></li>
                                <li><b>{ userDetails.date_of_birth } Born</b></li>

                                { (userDetails.height > 0) ? <li><b>{ userDetails.height } Feet</b></li> : '' }

                                { (userPhysicalData.weight) ? <li><b>{ userPhysicalData.weight } KG</b></li> : '' }
                                
                                { (userEducationCareerData.highest_education_title) ? <li><b>{ userEducationCareerData.highest_education_title }</b></li> : '' }

                                { (userEducationCareerData.highest_education_title) ? <li><b>{ userEducationCareerData.occupation_title }</b></li> : '' }

                                { (userSpiritualData.caste_title) ? <li><b>{ userSpiritualData.caste_title }</b></li> : '' }

                                { (userSpiritualData.religion_title) ? <li><b>{ userSpiritualData.religion_title }</b></li> : '' }
                            </ul>
                        </div> : ''
                    }
                    {/* END PROFILE ABOUT */}

                    {/* PROFILE ABOUT */}
                    {(isLoggedIn && userPackageDetails != null ) ? 
                        <div class="pr-bio-c pr-bio-info text-left m-t-20">
                            <h5 className='m-b-10'>Package</h5>
                            <ul>
                                <li><b>{ userPackageDetails.current_package }</b></li>
                                <li><b>&#8377; { userPackageDetails.package_price }</b></li>
                                <li><b>{ userPackageDetails.payment_type }</b></li>
                            </ul>
                        </div> : ''
                    }
                    {/* END PROFILE ABOUT */}
                  {/* <div class="menu-pop-soci">
                      <ul>
                          <li><Link to="/#!"><i class="fa fa-facebook" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-twitter" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-whatsapp" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-youtube-play" aria-hidden="true"></i></Link></li>
                          <li><Link to="/#!"><i class="fa fa-instagram" aria-hidden="true"></i></Link></li>
                      </ul>
                  </div>
                  <div class="late-news">
                      <h4>Latest news</h4>
                      <ul>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/1.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/3.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                          <li>
                              <div class="rel-pro-img"> <img src={"/assets/images/couples/4.jpg?x="+CONSTANTS.RANDOM_PWD} alt="" loading="lazy" /> </div>
                              <div class="rel-pro-con">
                                  <h5>Long established fact that a reader distracted</h5>
                                  <span class="ic-date">12 Dec 2023</span> 
                              </div>
                              <Link to="/blog-detail.html" class="fclick"></Link> 
                          </li>
                      </ul>
                  </div> */}
                  <div class="prof-rhs-help">
                      <div class="inn">
                          <h3>Tell us your Needs</h3>
                          <p>Tell us what kind of service you are looking for.</p>
                          {(isLoggedIn) ? <Link to="/contact.html">Contact Us</Link> : <Link to="/sign-up.html">Register</Link> }
                      </div>
                  </div>
              </div>
          </div>
          {/* END MOBILE MENU POPUP  */}
          {/* MOBILE USER PROFILE MENU POPUP  */}
          <div class="mob-me-all dashbord_menu">
              <div class="mob-me-clo"><img src={"/assets/images/icon/close.svg?x="+CONSTANTS.RANDOM_PWD} alt="" /></div>
              <div class="mv-bus">
                  <div class="head-pro">
                      <img src={user_avatar} alt={full_name} loading="lazy" /> <b>user profile</b><br />
                      <h4>{full_name}</h4>
                  </div>
                  <ul>
                      <li><Link to="/login.html">Login</Link></li>
                      <li><Link to="/sign-up.html">Sign-up</Link></li>
                      <li><Link to="/plans.html">Pricing plans</Link></li>
                      <li><Link to="/all-profiles.html">Browse profiles</Link></li>
                  </ul>
              </div>
          </div>
          {/* END USER PROFILE MENU POPUP  */} 
      </div>
    )
}

export default Header