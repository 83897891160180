import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios';
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';

import * as CONSTANTS from '../../Constants';
import * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import Top from '../../components/common/top';
import Header from '../../components/common/header';
import PlanItems from '../common/plan_item';
import Footer from '../../components/common/footer';

let rest_params = {};

function Plans()
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const[payPlans, setPayPlans] = useState({});
    const[isLoading, setIsLoading] = useState(true);
    const[paymentGateway, setPaymentGateway] = useState('phonepe');

    const modalOpenPaymentBtnRef = useRef(null);
    const modalClosePaymentBtnRef = useRef(null);

    function getPayPackages()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_plans', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setPayPlans(responce.data.plans_data);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        console.log(Object.keys(payPlans).length);
        if(Object.keys(payPlans).length < 1)
            getPayPackages();
    }, [payPlans])

    function RenderPlanProps(plan_props)
    {
        //console.log(plan_props);
        if(plan_props.length > 0)
        {
            return plan_props.slice(0,1).map((plan_prop) => {
                return (<li><i class="fa fa-check" aria-hidden="true"></i>{plan_prop.express_interest}
                </li>);
            })
        }
    }

    function List({ list }) {
        return (
            <ul>
                {Object.entries(list).map((item, key) => {
                    let item_details = item[1];
                    //console.log(item_details.amount);
                    return <li>
                                <div class="pri-box">
                                    <h2>{item_details.name}</h2>
                                    <p>Printer took a type and scrambled </p>
                                    <a href="sign-up.html" class="cta">Get Started</a>
                                    <span class="pri-cou"><b>${item_details.amount}</b>/mo</span>
                                    <ol>
                                    {
                                        //console.log(item_details.plan_description)
                                        Object.entries(item_details.plan_description).map((plan_prop, key) => {
                                            return <ListItem key={key} item={plan_prop[1]} />;
                                        })
                                    }
                                        {/* {
                                            ListItem(plan.plan_description)//plan_props_itemsRenderPlanProps(plan_props)
                                        } */}
                                        {/* <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                        <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li> */}
                                    </ol>
                                </div>
                            </li>
                })}
            </ul>
        );
      }
      
    function ListItem({ item }) {
        //console.log(item);
        const {label, value} = item;
        if (typeof value === 'object') {
            return (
                <li>{value}</li>
            );
        }
        return <li>{value}</li>;
    }

    function RenderPlans()
    {
        //console.log(payPlans);
        if(payPlans.length > 0)
        {
            return payPlans.map((plan, index) => {
                //console.log(plan);
                let plan_props_items = {};
                //plan_props_items = RenderPlanProps(plan.plan_description);
                //console.log(plan_props_items);
                return (
                    <li>
                        <div class="pri-box">
                            <h2>{plan.name}</h2>
                            <p>Printer took a type and scrambled </p>
                            <a href="sign-up.html" class="cta">Get Started</a>
                            <span class="pri-cou"><b>&#8377; {plan.amount}</b>/mo</span>
                            <ol>
                                {
                                    ListItem(plan.plan_description)//plan_props_itemsRenderPlanProps(plan_props)
                                }
                                {/* <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li> */}
                            </ol>
                        </div>
                    </li>
                )
            })
        }
        else
            return <li>No plans to load.</li>
    }

    function openPaymentModal(payment_gateway)
    {
        setPaymentGateway(payment_gateway);
        modalOpenPaymentBtnRef.current.click();
    }

    return (
        <div>
            <Top/>
            <Header/>
            {/* PRICING PLANS */}
            <section>
                <div class="plans-ban">
                    <div class="container">
                        <div class="row">
                            <span class="pri">Pricing</span>
                            <h1>Get Started <br/> Pick your Plan Now</h1>
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                            <span class="nocre">Premium Plans</span>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            {/* PRICING PLANS */}
            <section>
                <div class="plans-main">
                    <div class="container">
                        <div class="row">
                            <ul>
                                {
                                    (isLoading) ? <center>Loading...</center> : <PlanItems items={payPlans}  openPaymentModal={openPaymentModal} />/*<List list={payPlans} />RenderPlans()  <PlanItems items={payPlans} /> payPlans.map((index, value) => {
                                        <li>
                                            <div class="pri-box">
                                                <h2>Free</h2>
                                                <p>Printer took a type and scrambled </p>
                                                <a href="sign-up.html" class="cta">Get Started</a>
                                                <span class="pri-cou"><b>$0</b>/mo</span>
                                                <ol>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i> 5 Premium Profiles view /mo
                                                    </li>
                                                    <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                                    <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li>
                                                </ol>
                                            </div>
                                        </li>
                                    }) */
                                }
                                {/* <li>
                                    <div class="pri-box">
                                        <h2>Free</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$0</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i> 5 Premium Profiles view /mo
                                            </li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-close close" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div class="pri-box pri-box-pop">
                                        <span class="pop-pln">Most popular plan</span>
                                        <h2>Gold</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$349</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> 20 Premium Profiles view /mo</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <div class="pri-box">
                                        <h2>Platinum</h2>
                                        <p>Printer took a type and scrambled </p>
                                        <a href="sign-up.html" class="cta">Get Started</a>
                                        <span class="pri-cou"><b>$549</b>/mo</span>
                                        <ol>
                                            <li><i class="fa fa-check" aria-hidden="true"></i> 50 Premium Profiles view /mo</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Free user profile can view</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>View contact details</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Send interest</li>
                                            <li><i class="fa fa-check" aria-hidden="true"></i>Start Chat</li>
                                        </ol>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <a ref={modalOpenPaymentBtnRef} style={{display:'none'}} data-toggle="modal" data-target="#payment_scanner_modal">login</a>
                <div class="modal fade" id="payment_scanner_modal">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content notification-modal">

                            {/* Modal Header */}
                            <div class="modal-header">
                                <h4 class="modal-title seninter-tit">
                                    <span className='intename2'>{t('payment')}</span>
                                </h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>

                            {/* Modal body */}
                            <div class="modal-body seninter">
                                <div class="text-center"><img src={CONSTANTS.SERVER_BASE_URL+'/template/front/images/'+paymentGateway+'.png'} width={50+'%'} /></div>
                            </div>

                            {/* Modal footer */}
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal" ref={modalClosePaymentBtnRef}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}
            <Footer/>
        </div>
    )
}

export default Plans