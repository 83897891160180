import React, { useState, useEffect } from 'react'

import axios from 'axios';
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

let values = {};

function ProfileActionPanel(props)
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [user_id] = useState(COMMONFUNCTIONS.getSession('member_id'));
    const [selected_member_id] = useState(props.item.member_id);
    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [isInterested, setIsInterested] = useState(props.item.is_interested);
    const [isShortlisted, setShortlisted] = useState(props.item.is_shortlisted);
    const [isFollowed, setFollowed] = useState(props.item.is_followed);
    const [isIgnored, setIgnored] = useState(props.item.is_ignored);
    const [isReported, setReported] = useState(props.item.is_reported);

    const [interestedPopupOpen, setInterestedPopupOpen] = useState(false);

    useEffect(() => { console.log(props.item.member_id+' '+isInterested); }, [isInterested, isShortlisted, isFollowed, isIgnored, isReported ]);

    function gotoExpressInterest (member_id)
	{
        if (isLoggedIn)
        {
            COMMONFUNCTIONS.getUsersRemainingInterests(user_id);
            //this.AlertProLoader.open();
            
            setTimeout(
                () =>
                {
                    props.setSelectedMemberId(member_id);
                    var rem_interests = COMMONFUNCTIONS.getSession('user_interest_count');
                    /* //console.log(COMMONFUNCTIONS.getSession('user_interest_count'));
                    props.setExpressModalHeaderText(t('confirm_express_interest'));
                    props.setContentExpressModal(<p class='text-center'><b>{t('you_have_remaining_express_interest') +' '+ rem_interests +' '+ t('times')}</b><br/><br/><span>**N.B. {t('expressing_an_interest_will_cost_1_from_your_remaining_interests')}**</span></p>)

                    props.modalOpenExpressBtnRef.current.click();
                    setIsInterested(true); */

                    confirmAlert({
                        customUI: ({ onClose }) => {
                                return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_express_interest')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('you_have_remaining_express_interest') +' '+ rem_interests +' '+ t('times')}</b><br/><br/><span>**N.B. {t('expressing_an_interest_will_cost_1_from_your_remaining_interests')}**</span></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                                    //this.handleClickConfirm();
                                    props.updateExpressInterest(member_id);
                                    setIsInterested(true);
                                    setTimeout(() =>{onClose();},1000);                                    
                                  }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                                  </div>
                                );
                            }
                      }); 

                },
                1000
              );
        }
        else
		{
            props.setTextLoginModal('Please Log In To Express Interest On This Member.');
            props.modalOpenLoginBtnRef.current.click();
            /* COMMONFUNCTIONS.commonMessageInfoToast('Please Log In To Express Interest On This Member.');
            setTimeout(() => { gotoLogin(); }, 2000); */
		}
    }

    function gotoIgnore (member_id)
	{
        if (isLoggedIn)
        {
            confirmAlert({
                customUI: ({ onClose }) => {
                        return (<div class="modal fade show" id="expression_modal" aria-modal="true" style={{paddingRight: '15px',display: 'block'}}><div class="modal-dialog modal-md"><div class="modal-content notification-modal"><div class="modal-header"><h4 class="modal-title seninter-tit"><span class="intename2">{t('confirm_ignore')}</span></h4><button type="button" class="close" data-dismiss="modal">×</button></div><div class="modal-body seninter"><div class="text-center"><p class="text-center"><b>{t('are_you_sure_that_you_want_to_ignore_this_member?')}</b></p></div></div><div class="modal-footer"><button type="button" class="btn btn-primary" onClick={() => {
                            //this.handleClickConfirm();
                            saveIgnorelist(user_id ,member_id);
                            setIgnored(true);
                            setTimeout(() =>{onClose();},1000);                                    
                          }}>Confirm</button><button type="button" class="btn btn-outline-danger"  onClick={onClose} data-dismiss="modal">Cancel</button></div></div></div>
                          </div>
                        );
                    }
              }); 
        }
        else
		{
			props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
		}
    }

    /* function updateExpressInterest(member_id)
    {
        if (isLoggedIn)
        {
            COMMONFUNCTIONS.commonMessageSuccessToast('You have successfully expressed interest.');
            let act_status = COMMONFUNCTIONS.saveExpressInterest(user_id ,member_id);
            if(act_status)
                setInterested(true);
            else
                setInterested(false);

            props.modalCloseExpressBtnRef.current.click();
        }
		else
		{
			props.setTextLoginModal('Please Log In To Express Interest On This Member.');
            props.modalOpenLoginBtnRef.current.click();
		}
    } */

    function gotoShortlist (member_id)
	{
        if (isLoggedIn)
        {
            /* var remainingInterest = COMMONFUNCTIONS.getUsersRemainingInterests(user_id);
            this.AlertProLoader.open();
            
            setTimeout(
                () =>
                {
                    console.log(remainingInterest._55);
                    this.setState({
                        remaining_interest_count : remainingInterest._55,
                        alert_message : "Remaining Express Interest(s): "+remainingInterest._55,
                    });
                    //alert_message = "Remaining Express Interest(s): "+this.state.remaining_interest_count;
                    this.AlertPro.open();
                    this.AlertProLoader.close();
                },
                3000
              ); */
        }
        else
		{
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
		}
    }

    function saveShortlist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_shortlist',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setShortlisted(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setShortlisted(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Shortlist This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }
    
    function removeShortlist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/remove_shortlist',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setShortlisted(false);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setShortlisted(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Remove This Member From Shortlist.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function saveFollowlist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_follow',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setFollowed(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setFollowed(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Follow This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }
    
    function addUnfollowList (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_unfollow',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    setFollowed(false);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    setFollowed(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Un-Follow This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function saveIgnorelist (user_id, member_id)
    {
        if (isLoggedIn)
        {
            values['user_id'] = user_id;
            values['member_id'] = member_id;
            values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/add_ignore',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageSuccessToast(response.data.message);
                    isIgnored(true);
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    isIgnored(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        else
        {
            props.setTextLoginModal('Please Log In To Ignore This Member.');
            props.modalOpenLoginBtnRef.current.click();
        }
    }

    function gotoUserProfile(member_id)
    {
        //console.log(member_id);
        navigate("/profile-details.html", { state: {member_id : member_id}, replace: true });
    }

    return (
        <>
            <div class="links profile-action-btn-panel">
                {
                    (isInterested) ? 
                        <a title='Interest Expressed' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Expressed Interest In This Member.'); }} className='active' ><i class="fa fa-heart"></i></a>
                        : 
                        <a title='Express Interest' onClick={() => {gotoExpressInterest(props.item.member_id)}}><i class="fa fa-heart"></i></a>
                }

                {
                    (isShortlisted) ? 
                        <a title='Shortlisted' onClick={() => { removeShortlist(user_id, props.item.member_id); }} className='active' ><i class="fa fa-list-ul"></i></a>
                        : 
                        <a title='Shortlist' onClick={() => { saveShortlist(user_id, props.item.member_id); }}><i class="fa fa-list-ul"></i></a>
                }

                {
                    (isFollowed) ? 
                        <a title='Unfollow' onClick={() => { addUnfollowList(user_id, props.item.member_id); }} className='active' ><i class="fa fa-star"></i></a>
                        : 
                        <a title='Follow' onClick={() => { saveFollowlist(user_id, props.item.member_id); }}><i class="fa fa-star"></i></a>
                }

                {
                    (isIgnored) ? 
                        <a title='Unfollow' onClick={() => { COMMONFUNCTIONS.commonMessageInfoToast('You Have Already Ignored This Member.'); }} className='active' ><i class="fa fa-ban"></i></a>
                        : 
                        <a title='Ignore Profile' onClick={() => { gotoIgnore(props.item.member_id); }}><i class="fa fa-ban"></i></a>
                }
                
                {/* <a title='Shortlist' onClick={() => {gotoUserProfile(props.item.member_id)}}><i class="fa fa-list-ul"></i></a> 
                <a title='Follow' onClick={() => {gotoUserProfile(props.item.member_id)}}><i class="fa fa-star"></i></a>
                <a title='Ignore Profile' onClick={() => {gotoUserProfile(props.item.member_id)}}><i class="fa fa-ban"></i></a>*/}
                <a title="Go to profile" onClick={() => {gotoUserProfile(props.item.member_id)}}><i class="fa fa-id-card"></i></a>
            </div>
        </>
    )
}

export default ProfileActionPanel