import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import SubmitLoader from '../../components/common/submit_loader';

let values = {};

function LeftSearch(props)
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    console.log(props);

    //console.log(props.common_data);

    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));

    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));

    const [gender_data] = useState(common_data.common_data.gender_data);
    const [country_data] = useState(common_data.common_data.country_data);
    const [religion_data] = useState(common_data.common_data.religion_data);
    const [language_data] = useState(common_data.common_data.language_data);
    const [occupation_data] = useState(common_data.common_data.occupation_data);
    const [marital_status_data] = useState(common_data.common_data.marital_status_data);

    const [states, setStates] = useState(null);
    const [selected_state, setSelectedState] = useState(22);
    const [cities, setCities] = useState(null);

    const [submitBtnText, changeSubmitBtnText] = useState('Search');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    useEffect(() => {
        if(states == null)
            changeCountry(101);
    })

    const SearchSchema = Yup.object({
        //email: Yup.string().email('Invalid email address').required('Required'),
        username: Yup.string().required('Required'),
        password: Yup.string()
            .min(4, 'Must be 4 characters or more')
            .required('Required'),
    });

    const changeCountry = (val) => {
        getStatesByCountry(val);
    };

    function getStatesByCountry(country_id)
    {
        values['country_id'] = country_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_state_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
            {
                setStates(response.data.state_data);
                if(country_id == 101)
                    changeState(22);
            }
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const state_options = (states) => {
        if (typeof states === 'object' && states !== null )
        {
            return states.map((el) => <option key={el.state_id} value={el.state_id} selected={ (selected_state == el.state_id) ? true : false }>{el.name}</option>);
        }
    }

    const changeState = (val) => {
        getCitiesByState(val);
    };

    function getCitiesByState(state_id)
    {
        values['state_id'] = state_id;
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;

        axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_city_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
        .then(response => {
            if(response.data.success)
                setCities(response.data.city_data);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
        })
        .catch(error => {
            console.log(error);
            //if(error.response.data.message.length > 0)
            if(error.response !== undefined && error.response !== '' && error.response !== null)
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
            else
                COMMONFUNCTIONS.commonMessageErrorToast(error.message);
        });
    }

    const city_options = (cities) => {
        if (typeof cities === 'object' && cities !== null )
        {
            return cities.map((el) => <option key={el.city_id} value={el.city_id}>{el.name}</option>);
        }
    }

    /* const handleChange = (event) => {
        //this.setState({ value: event.target.value });
        console.log(event.target.value);
        props.changeFilterGenderFunction(event)
    }; */

    return (
        <div>
            <span class="filter-clo" onClick={() => { props.setMobFilterShow('none') }}>+</span>
            {/* START */}
            { !isLoggedIn &&  <div class="filt-com lhs-cate">
                    <h4><i class="fa fa-search" aria-hidden="true"></i> {t("i_am_looking_for")}</h4>
                    <div class="form-group">
                        <select class="chosen-select" name="gender" onChange={ (event) => { /* handleChange(event);props.resetResetRecordsListPanel(true); */ props.changeFilterGenderFunction(event);  } /* props.changeFilterGenderFunction(this.value) *//* props.search_params = this.value;props.fetchData(); */}>
                            <option value="">{t("i_am_looking_for")}</option>
                            {gender_data.map((option, index) => (
                                <option key={index} value={option.gender_id} selected={ (props.search_params.gender == option.gender_id) ? true : false } >{option.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            }
            
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-clock-o" aria-hidden="true"></i>{t("age")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="age_range" onChange={ (event) => { props.changeFilterAgeFunction(event);  } }>
                        <option value="">{t("select")}</option>
                        <option value="18-30" selected={ (props.search_params.age_range == '18-30') ? true : false }>18 to 30</option>
                        <option value="31-40" selected={ (props.search_params.age_range == '31-40') ? true : false }>31 to 40</option>
                        <option value="41-50" selected={ (props.search_params.age_range == '41-50') ? true : false }>41 to 50</option>
                        <option value="51-60" selected={ (props.search_params.age_range == '51-60') ? true : false }>51 to 60</option>
                        <option value="61-70" selected={ (props.search_params.age_range == '61-70') ? true : false }>61 to 70</option>
                        <option value="71-80" selected={ (props.search_params.age_range == '71-80') ? true : false }>71 to 80</option>
                        <option value="81-90" selected={ (props.search_params.age_range == '81-90') ? true : false }>81 to 90</option>
                        <option value="91-100" selected={ (props.search_params.age_range == '91-100') ? true : false }>91 to 100</option>
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-bell-o" aria-hidden="true"></i>{t("member_id")}</h4>
                <div class="form-group">
                    <input type="text" id="filter_member_id" class="form-control" placeholder={t("member_id")} name="member_id" onChange={ (event) => { props.changeFilterMemberIdFunction(event);  } } />
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-bell-o" aria-hidden="true"></i>{t("marital_status")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="marital_status" onChange={ (event) => { props.changeFilterMaritalStatusFunction(event);  } }>
                        <option value="">- {t('choose')} -</option>
                        {marital_status_data.map((option, index) => (
                            <option key={index} value={option.marital_status_id} selected={ (props.search_params.marital_status == option.marital_status_id) ? true : false }>{option.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-bell-o" aria-hidden="true"></i>{t("religion")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="religion" onChange={ (event) => { props.changeFilterReligionFunction(event);  } }>
                        <option value="">- {t('choose')} -</option>
                        {religion_data.map((option, index) => (
                            <option key={index} value={option.religion_id} selected={ (props.search_params.religion == option.religion_id) ? true : false }>{option.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-bell-o" aria-hidden="true"></i>{t("mother_tongue")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="language" onChange={ (event) => { props.changeFilterLanguageFunction(event);  } }>
                        <option value="">- {t('choose')} -</option>
                        {language_data.map((option, index) => (
                            <option key={index} value={option.language_id} selected={ (props.search_params.language == option.language_id) ? true : false }>{option.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-bell-o" aria-hidden="true"></i>{t("profession")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="profession" onChange={ (event) => { props.changeFilterProfessionFunction(event);  } }>
                        <option value="">- {t('choose')} -</option>
                        {occupation_data.map((option, index) => (
                            <option key={index} value={option.occupation_id}>{option.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-map-marker" aria-hidden="true"></i>{t("country")}</h4>
                <div class="form-group">
                    <select class="chosen-select" name="country"  onChange={(event) => {
                                                console.log(event.target.value);
                                                props.changeFilterCountryFunction(event);
                                                changeCountry(event.target.value);
                                                //this.handleChange();
                                                //setStates(event.target.value);
                                                //setCountryVal(event.target.value);
                                            }}>
                        <option value="">- {t('choose')} -</option>
                        {country_data.map((option, index) => (
                            <option key={index} value={option.country_id} selected={ (option.country_id == '101') ? true : false }>{option.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-map-marker" aria-hidden="true"></i>{t("state")}</h4>
                <div class="form-group">
                    <select class="chosen-select" id="state" name='state' onChange={(event) => {
                        console.log(event.target.value);
                        props.changeFilterStateFunction(event);
                        //this.handleChange();
                        changeState(event.target.value);
                        //setStateVal(event.target.value);
                    }} >
                        <option value="">- {t('choose')} -</option>
                        { state_options(states) }
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            <div class="filt-com lhs-cate">
                <h4><i class="fa fa-map-marker" aria-hidden="true"></i>{t("city")}</h4>
                <div class="form-group">
                    <select class="chosen-select" id="city" name='city' onChange={(event) => {
                        console.log(event.target.value);
                        props.changeFilterCityFunction(event);
                    }} >
                        <option value="">- {t('choose')} -</option>
                        { city_options(cities) }
                    </select>
                </div>
            </div>
            {/* END */}
            {/* START */}
            {/* <div class="filt-com lhs-rati lhs-avail lhs-cate">
                <h4><i class="fa fa-thumbs-o-up" aria-hidden="true"></i> Availablity</h4>
                <ul>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="" name="expert_avail" class="rating_check" id="exav1"
                                checked />
                            <label for="exav1">All</label>
                        </div>
                    </li>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="" name="expert_avail" class="rating_check"
                                id="exav2" />
                            <label for="exav2">Available</label>
                        </div>
                    </li>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="" name="expert_avail" class="rating_check"
                                id="exav3" />
                            <label for="exav3">Offline</label>
                        </div>
                    </li>
                </ul>
            </div> */}
            {/* END */}

            {/* START */}
            <div class="filt-com lhs-rati lhs-ver lhs-cate">
                <h4><i class="fa fa-shield" aria-hidden="true"></i>{t("profile")}</h4>
                <ul>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="all" name="search_member_type" class="rating_check" id="s_all_members" onChange={ (event) => { props.changeFilterMemberTypeFunction(event); } } checked={ (props.selectedMemberType == 'all') ? "checked" : '' } />
                            <label for="s_all_members">All</label>
                        </div>
                    </li>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="premium_members" name="search_member_type" class="rating_check"
                                id="s_premium_members" onChange={ (event) => { props.changeFilterMemberTypeFunction(event); } }  checked={ (props.selectedMemberType == 'premium_members') ? "checked" : '' } />
                            <label for="s_premium_members">Premium</label>
                        </div>
                    </li>
                    <li>
                        <div class="rbbox">
                            <input type="radio" value="free_members" name="search_member_type" class="rating_check"
                                id="s_free_members" onChange={ (event) => { props.changeFilterMemberTypeFunction(event); } }  checked={ (props.selectedMemberType == 'free_members') ? "checked" : '' } />
                            <label for="s_free_members">Free</label>
                        </div>
                    </li>
                </ul>
            </div>
            {/* END */}
            {/* START */}
            {/* <div class="filt-com filt-send-query">
                <div class="send-query">
                    <h5>What are you looking for?</h5>
                    <p>We will help you to arrage the best match to you.</p>
                    <a href="#!" data-toggle="modal" data-target="#expfrm">Send your queries</a>
                </div>
            </div> */}
            {/* END */}
        </div>
    )
}

export default LeftSearch