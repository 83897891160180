import React, { useState, useEffect } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import Slider from "react-slick";

import * as CONSTANTS from '../../Constants';
import  * as COMMONFUNCTIONS from '../../utils/CommonFunctions';

import SubmitLoader from '../../components/common/submit_loader';

let rest_params = {};

function Search(props)
{
    const {t} = useTranslation();
    const navigate = useNavigate();

    console.log(props.common_data);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn] = useState(COMMONFUNCTIONS.getSession('isUserLoggedIn'));
    const [common_data] = useState(JSON.parse(COMMONFUNCTIONS.getSession('common_data')));

    const [gender_data, setGenderData] = useState([]);
    const [religion_data, setReligionData] = useState([]);
    const [language_data, setLanguageData] = useState([]);
    const [marital_status_data, setMaritalStatusData] = useState([]);
    const [slider_images_data, setSliderImagesData] = useState([]);

    /* const [gender_data] = useState(common_data.common_data.gender_data);
    const [religion_data] = useState(common_data.common_data.religion_data);
    const [language_data] = useState(common_data.common_data.language_data);
    const [slider_images_data] = useState(common_data.common_data.slider_images); */
    const [current_slider_image_index, setCurrentSliderImageIndex] = useState(0);
    const [current_slider_image, setCurrentSliderImage] = useState(slider_images_data[current_slider_image_index]);

    //console.log(slider_images_data[0]);

    console.log(props);


    /* setTimeout(() => {
            console.log('Our data is fetched');
            const [gender_data] = useState(common_data.common_data.gender_data);
            const [religion_data] = useState(common_data.common_data.religion_data);
            const [language_data] = useState(common_data.common_data.language_data);
    }, 1000); */

    const [submitBtnText, changeSubmitBtnText] = useState('Search');
    const [submitBtnStatus, changeSubmitBtnStatus] = useState(false);

    const SearchSchema = Yup.object({
        //email: Yup.string().email('Invalid email address').required('Required'),
        username: Yup.string().required('Required'),
        password: Yup.string()
            .min(4, 'Must be 4 characters or more')
            .required('Required'),
    });

    useEffect(() => {
        setCommonData(common_data);
        setTimeout(() => {
                /* let random = slider_images_data[Math.floor(Math.random() * slider_images_data.length)]
                console.log(random);
                setCurrentSliderImage(random); */
                setBgBanner(parseInt(current_slider_image_index));
        }, 5000);
    });

    function setCommonData(common_data)
    { 
        setGenderData(common_data.common_data.gender_data);
        setReligionData(common_data.common_data.religion_data);
        setLanguageData(common_data.common_data.language_data);
        setMaritalStatusData(common_data.common_data.marital_status_data);
        //setSliderImagesData(common_data.common_data.slider_images);
        if(slider_images_data.length < 1)
            getBannerImages();
    }

    function getBannerImages()
    {
        rest_params[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
        try {
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/main/get_banner_images', rest_params, { headers : COMMONFUNCTIONS.getPostHeaders() })
            .then(responce => {
                //console.log(responce.data.plans_data);
                if(responce.data.success)
                {
                    setSliderImagesData(responce.data.images_data);
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response !== undefined && error.response !== '' && error.response !== null)
                    COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                else
                    COMMONFUNCTIONS.commonMessageErrorToast(error.message);
            });
        } catch(error) {
            console.log(error);
            COMMONFUNCTIONS.commonMessageErrorToast(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    function setBgBanner(index)
    {
        console.log((index+1) +'>'+ slider_images_data.length);
        if((index+1) > slider_images_data.length)
        {
            console.log('0');
            console.log(slider_images_data[index]);
            setCurrentSliderImageIndex(0);
            setCurrentSliderImage(slider_images_data[0]);
        }
        else
        {
            console.log(index);
            console.log(slider_images_data[index]);
            setCurrentSliderImageIndex(index+1);
            setCurrentSliderImage(slider_images_data[index]);
        }
    }

    /* const SearchForm = (values, { setSubmitting }) => {
        changeSubmitBtnText(<i class="fa fa-spinner fa-spin"></i>);
        changeSubmitBtnStatus(true);
        values[CONSTANTS.REST_KEY_NAME] = CONSTANTS.REST_KEY_VALUE;
            axios.post(CONSTANTS.SERVER_BASE_URL+'/api/auth/ajax_member_list',values, {headers: COMMONFUNCTIONS.getPostHeaders()})
            .then(response => {
                console.log(response);
                if(response.data.success)
                {
                    COMMONFUNCTIONS.commonMessageInfoToast('Data searched successfully !!!');
                    COMMONFUNCTIONS.GoToPage(navigate, '/members.html');
                    ///navigate('/index.html');
                }
                else
                {
                    COMMONFUNCTIONS.commonMessageErrorToast(response.data.message);
                    changeSubmitBtnText('Search');
                    changeSubmitBtnStatus(false);
                }
                    
            })
            .catch(error => {
                console.log(error);
                COMMONFUNCTIONS.commonMessageErrorToast(error.response.data.message);
                changeSubmitBtnText('Search');
                changeSubmitBtnStatus(false);
            });
            setSubmitting(false);
    } */


    const SearchForm = (values, { setSubmitting }) => {
        //event.preventDefault();
        values['count'] = 0;
        console.log(values);
        if (values) {
            navigate("/members.html", { state: {search_params : values}, replace: true });
        }
    }

    var settings = {
        className: 'ban-sli',
        infinite: true,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 10000,
        class: 'ban-sli'
      };

    /* gender:
aged_from:
member_id:B57AE3FA101
marital_status:
religion:
caste:
sub_caste:
:
profession:
country:
state:
city:
min_height:0.00
max_height:8.00
search_member_type:all
count:0
method:search */



    //let new_common_data = JSON.parse(common_data);
    /* console.log(common_data);
    console.log(common_data.common_data);
    console.log(common_data.common_data.gender_data); */
    return (
        <div>
            {/* BANNER & SEARCH */}
            <section>
                <div class="str">
                    <div class="hom-head" style={{backgroundImage: 'url('+current_slider_image+')'}}
                    /* style={{backgroundImage: `url(../images/ban-bg.jpg)`;
                    backgroundPositionX: '0px';
                    background-position-y: center;
                    background-size: cover;
                    background-repeat-x: no-repeat;
                    background-repeat-y: no-repeat;
                    background-attachment: initial;
                    background-origin: initial;
                    background-clip: initial;
                    background-color: initial;}} */
                    
                    /* style={{background: "url("+{current_slider_image}+") 0px center / cover no-repeat"}} */>
                        <div class="container">
                            <div class="row">
                                <div class="hom-ban">
                                    <div class="ban-tit">
                                        <span><i class="no1">#1</i> Matrimony</span>
                                        <h1>Find your<br/><b>Right Match</b> here</h1>
                                        <p>Most trusted Matrimony in the India.</p>
                                    </div>
                                    <div class="ban-search form-select">
                                        <Formik
                                            initialValues={{  gender: '', age_range: '', marital_status: '', religion: '', language: '' }}
                                            /* validationSchema={SearchSchema} */
                                            onSubmit={SearchForm}
                                            >
                                        {formik => (
                                            <form onSubmit={formik.handleSubmit}>
                                            <ul>
                                                { (!isLoggedIn) && <li class="sr-look">
                                                    <div class="form-group">
                                                        <label>I'm looking for</label>
                                                        <select class="chosen-select" name="gender" onChange={formik.handleChange} value={formik.values.gender}>
                                                            <option value="">{t("I'm looking for")}</option>
                                                            {gender_data.map((option, index) => (
                                                                <option key={index} value={option.gender_id}>{option.name}</option>
                                                            ))}
                                                            {/* <option value="Men">Men</option>
                                                            <option value="Women">Women</option> */}
                                                        </select>
                                                    </div>
                                                </li> }

                                                <li class="sr-age">
                                                    <div class="form-group">
                                                        <label>Age</label>
                                                        <select class="chosen-select" name="age_range" onChange={formik.handleChange} value={formik.values.age_range}>
                                                            <option value="">Age</option>
                                                            <option value="18-30">18 to 30</option>
                                                            <option value="31-40">31 to 40</option>
                                                            <option value="41-50">41 to 50</option>
                                                            <option value="51-60">51 to 60</option>
                                                            <option value="61-70">61 to 70</option>
                                                            <option value="71-80">71 to 80</option>
                                                            <option value="81-90">81 to 90</option>
                                                            <option value="91-100">91 to 100</option>
                                                        </select>
                                                    </div>
                                                </li>

                                                { (isLoggedIn) && <li class="sr-look">
                                                    <div class="form-group">
                                                        <label>{t("marital_status")}</label>
                                                        <select class="chosen-select" name="marital_status" onChange={formik.handleChange} value={formik.values.marital_status}>
                                                            <option value="">{t("marital_status")}</option>
                                                            {marital_status_data.map((option, index) => (
                                                                <option key={index} value={option.marital_status_id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </li> }

                                                <li class="sr-reli">
                                                    <div class="form-group">
                                                        <label>Religion</label>
                                                        <select class="chosen-select" name="religion" onChange={formik.handleChange} value={formik.values.religion}>
                                                            <option>Religion</option>
                                                            {religion_data.map((option, index) => (
                                                                <option key={index} value={option.religion_id}>{option.name}</option>
                                                            ))}
                                                            {/* <option>Hindu</option>
                                                            <option>Muslim</option>
                                                            <option>Jain</option>
                                                            <option>Christian</option> */}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li class="sr-cit">
                                                    <div class="form-group">
                                                        <label>Mother Tongue</label>
                                                        <select class="chosen-select" name="language" onChange={formik.handleChange} value={formik.values.language}>
                                                            <option>Language</option>
                                                            <option value={0}>Any</option>
                                                            {language_data.map((option, index) => (
                                                                <option key={index} value={option.language_id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li class="sr-btn">
                                                    {/* <input type="submit" value="Search"/> */}
                                                    <SubmitLoader Button_Title={submitBtnText} Button_Status={submitBtnStatus}  Button_Type={'submit'} />
                                                </li>
                                            </ul>
                                            </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END */}

            {/* BANNER SLIDER */}
            <section>
                <div class="hom-ban-sli">
                    <div>
                        {/* <ul class="ban-sli">
                            {slider_images_data.map((option, index) => (
                                <li>
                                    <div class="image">
                                        <img src={option} alt="" loading="lazy" />
                                    </div>
                                </li>
                            ))}
                        </ul> */}
                        {/* <Slider {...settings}>
                            <div class="image">
                                <img src="http://lagnasohalaa.com/uploads/home_page/slider_image/slider_image_1.jpg" alt="" loading="lazy" />
                            </div>
                            <div class="image">
                                <img src="http://lagnasohalaa.com/uploads/home_page/slider_image/slider_image_3.jpg" alt="" loading="lazy" />
                            </div>
                        </Slider> */}
                    </div>
                </div>
            </section>
            {/* END */}
        </div>
    )
}

export default Search