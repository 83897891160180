export const PROJECT_TITLE = 'Lagna Sohalaa Pvt Ltd';
//export const SERVER_BASE_URL = 'http://localhost/projects/lagnasohala/matrimony_2';
export const SERVER_BASE_URL = 'https://stagingm.lagnasohalaa.com';
//export const SERVER_BASE_URL = 'https://api.lagnasohalaa.com';
export const PUBLIC_CONTACT_NUMBER = '+91 7887861234';
export const CONTACT_NUMBER = '7887861234';
export const PUBLIC_CONTACT_EMAIL = 'contact@lagnasohalaa.com';
export const PUBLIC_ADDRESS = '5th Floor, B-Square Building Plot No.3, Trimurti - Ambad Link Road, Opposite Atul Sweets, Nashik, Maharashtra, India – 422010';
export const PROFILE_DIR_PATH = '/uploads/profile_image/';
export const PLAN_DIR_PATH = '/uploads/plan_image/';
export const STORY_DIR_PATH = '/uploads/happy_story_image/';
export const GALLERY_DIR_PATH = '/uploads/photo_gallery/';
export const DEFAULT_PLAN_IMAGE = 'default_image.jpg';
export const DEFAULT_PROFILE_IMAGE = 'default.jpg';
export const REST_KEY_NAME = 'LSM-AUTH-API-KEY';
export const REST_KEY_VALUE = '4GeRbtbLSBeDg3ZxZ48o';
export const AUTH_VALUE = 'bGFnbmFzb2hhbGFfYWRtaW46TGFnbmFATWF0cmltb255LmMwbQ==';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.matrimony.lagnasohalaa';
export const RANDOM_PWD = '6g9mhmCpo0';